import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Info = makeShortcode("Info");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Info mdxType="Info">
Uniswap V1, while still fully functional, is no longer under active development. Looking for <Link to='/docs/v2/' mdxType="Link">V2 documentation</Link>?
    </Info>
    <h1 {...{
      "id": "formalized-model",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#formalized-model",
        "aria-label": "formalized model permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Formalized Model`}</h1>
    <p>{`Uniswap liquidity pools are autonomous and use the Constant Product Market Maker `}{`(`}<inlineCode parentName="p">{`x * y = k`}</inlineCode>{`)`}{`. This model was formalized and the smart contract implementation passed a lightweight formal verification.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/runtimeverification/verified-smart-contracts/blob/uniswap/uniswap/x-y-k.pdf"
        }}>{`Formalized Specification`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/runtimeverification/verified-smart-contracts/tree/uniswap/uniswap/results"
        }}>{`Lightweight Verification`}</a></li>
    </ul>
    <h2 {...{
      "id": "create-exchange",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#create-exchange",
        "aria-label": "create exchange permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Create Exchange`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`createExchange`}</inlineCode>{` function is used to deploy exchange contracts for ERC20 tokens that do not yet have one.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`factory.methods.createExchange(tokenAddress).send()
`}</code></pre>
    <p>{`Once an exchange is created the address can be retrieved with `}<a parentName="p" {...{
        "href": "../connect-to-uniswap/#get-exchange-address"
      }}><inlineCode parentName="a">{`getExchange`}</inlineCode></a>{`.`}</p>
    <h2 {...{
      "id": "exchange-reserves",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#exchange-reserves",
        "aria-label": "exchange reserves permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Exchange Reserves`}</h2>
    <p>{`Each exchange contract holds a liquidity reserve of ETH and its associated ERC20 token.`}</p>
    <h3 {...{
      "id": "eth-reserve",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#eth-reserve",
        "aria-label": "eth reserve permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ETH Reserve`}</h3>
    <p>{`The ETH reserve associated with an ERC20 token exchange is the ETH balance of the exchange smart contract.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const ethReserve = web3.eth.getBalance(exchangeAddress)
`}</code></pre>
    <h3 {...{
      "id": "erc20-reserve",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#erc20-reserve",
        "aria-label": "erc20 reserve permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ERC20 Reserve`}</h3>
    <p>{`The ERC20 reserve associated with an ERC20 token exchange is the ERC20 balance of the exchange smart contract.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const tokenReserve = tokenContract.methods.balanceOf(exchangeAddress)
`}</code></pre>
    <h2 {...{
      "id": "add-liquidity",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#add-liquidity",
        "aria-label": "add liquidity permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add Liquidity`}</h2>
    <p>{`Anyone who wants can join a Uniswap liquidity pool by calling the `}<inlineCode parentName="p">{`addLiquidity`}</inlineCode>{` function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`exchange.methods.addLiquidity(min_liquidity, max_tokens, deadline).send({ value: ethAmount })
`}</code></pre>
    <p>{`Adding liquidity requires depositing an equivalent `}<strong parentName="p">{`value`}</strong>{` of ETH and ERC20 tokens into the ERC20 token’s associated exchange contract.`}</p>
    <p>{`The first liquidity provider to join a pool sets the initial exchange rate by depositing what they believe to be an equivalent value of ETH and ERC20 tokens. If this ratio is off, arbitrage traders will bring the prices to equilibrium at the expense of the initial liquidity provider.`}</p>
    <p>{`All future liquidity providers deposit ETH and ERC20’s using the exchange rate at the moment of their deposit. If the exchange rate is bad there is a profitable arbitrage opportunity that will correct the price.`}</p>
    <h3 {...{
      "id": "parameters",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#parameters",
        "aria-label": "parameters permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Parameters`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`ethAmount`}</inlineCode>{` sent to `}<inlineCode parentName="p">{`addLiquidity`}</inlineCode>{` is the exact amount of ETH that will be deposited into the liquidity reserves. It should be 50% of the total value a liquidity provider wishes to deposit into the reserves.`}</p>
    <p>{`Since liquidity providers must deposit at the current exchange rate, the Uniswap smart contracts use `}<inlineCode parentName="p">{`ethAmount`}</inlineCode>{` to determine the amount of ERC20 tokens that must be deposited. This token amount is the remaining 50% of total value a liquidity provider wishes to deposit. Since exchange rate can change between when a transaction is signed and when it is executed on Ethereum, `}<inlineCode parentName="p">{`max_tokens`}</inlineCode>{` is used to bound the amount this rate can fluctuate. For the first liquidity provider, `}<inlineCode parentName="p">{`max_tokens`}</inlineCode>{` is the exact amount of tokens deposited.`}</p>
    <p>{`Liquidity tokens are minted to track the relative proportion of total reserves that each liquidity provider has contributed. `}<inlineCode parentName="p">{`min_liquidity`}</inlineCode>{` is used in combination with `}<inlineCode parentName="p">{`max_tokens`}</inlineCode>{` and `}<inlineCode parentName="p">{`ethAmount`}</inlineCode>{` to bound the rate at which liquidity tokens are minted. For the first liquidity provider, `}<inlineCode parentName="p">{`min_liquidity`}</inlineCode>{` does not do anything and can be set to 0.`}</p>
    <p>{`Transaction `}<inlineCode parentName="p">{`deadline`}</inlineCode>{` is used to set a time after which a transaction can no longer be executed. This limits the “free option” problem, where Ethereum miners can hold signed transactions and execute them based off market movements.`}</p>
    <h2 {...{
      "id": "remove-liquidity",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#remove-liquidity",
        "aria-label": "remove liquidity permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Remove Liquidity`}</h2>
    <p>{`Liquidity providers use the `}<inlineCode parentName="p">{`removeLiquidity`}</inlineCode>{` function to withdraw their portion of the reserves.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`exchange.methods.removeLiquidity(amount, min_eth, min_tokens, deadline).send()
`}</code></pre>
    <p>{`Liquidity is withdrawn at the same ratio as the reserves at the time of withdrawal. If the exchange rate is bad there is a profitable arbitrage opportunity that will correct the price.`}</p>
    <h3 {...{
      "id": "parameters-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#parameters-1",
        "aria-label": "parameters 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Parameters`}</h3>
    <p><inlineCode parentName="p">{`amount`}</inlineCode>{` specifies the number of liquidity tokens that will be burned. Dividing this amount by the total liquidity token supply gives the percentage of both the ETH and ER20 reserves the provider is withdrawing.`}</p>
    <p>{`Since exchange rate can change between when a transaction is signed and when it is executed on Ethereum, `}<inlineCode parentName="p">{`min_eth`}</inlineCode>{` and `}<inlineCode parentName="p">{`min_tokens`}</inlineCode>{` are used to bound the amount this rate can fluctuate.`}</p>
    <p>{`Same as in `}<inlineCode parentName="p">{`addLiquidity`}</inlineCode>{`, `}<inlineCode parentName="p">{`deadline`}</inlineCode>{` is used to set a time after which a transaction can no longer be executed.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      